const apiUrl = {
  authCheck: "/api/auth/check",
  mailCheck: "/api/user/mail/check",
  merchants: "/api/merchants",
  markets: "/api/markets",
  roles: "/api/roles?only=name,id",
  getRole: "/api/roles/{roleId}",
  createRole: "/api/roles",
  updateRole: "/api/roles/{roleId}",
  deleteRole: "/api/roles/{roleId}",
  getPermission: "/api/permissions/{permissionId}",
  createPermission: "/api/permissions",
  updatePermission: "/api/permissions/{permissionId}",
  deletePermission: "/api/permissions/{permissionId}",
  permissionsFromRole:
    "/api/roles/{roleId}/permissions?only=name&no_pagination=true",
  removePermissionsFromRole: "/api/roles/{roleId}/permissions/detach",
  addPermissionsToRole: "/api/roles/{roleId}/permissions/attach",
  getAvailablePermissions: "/api/roles/{roleId}/availablePermissions",
};
export const authUrl = {
  login: "/login",
  logout: "/logout",
  forgotPassword: "/forgot-password",
  forgotPasswordNotTenant: "/forgot-password",
  resetPassword: "/reset-password",
  tenantUser: "/api/users/{token}/tenant",
};
export const preferenceUrl = {
  getById: "/api/user/{id}/preference",
  update: "/api/user/preference",
};
export default apiUrl;
